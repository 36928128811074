@import "node_modules/bootstrap/scss/bootstrap";

.flame-bg {
	background: linear-gradient(0deg, rgba(153,51,153,1) 0%, rgba(255,51,153,1) 100%);
  	background-color: rgba(0, 0, 0, 0);
  	background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-attachment: scroll;
	background-image: linear-gradient(0deg, rgb(153, 51, 153) 0%, rgb(255, 51, 153) 100%);
	background-size: auto;
	background-origin: padding-box;
	background-clip: border-box;
	height: 100vh;
	width: 100vw;
}